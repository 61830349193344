import request from '@/utils/request'

export const getTemplateList = ( query ) => {
  return request( {
    url : '/productTemplate/listTemplate',
    method : 'get',
    params : query
  } )
}

export const getIndustryList = () => {
  return request( {
    url : '/system/dict/data/list?dictType=t_industry',
    method : 'get'
  } )
}

export const getSceneList = () => {
  return request( {
    url : '/system/dict/data/list?dictType=t_scene',
    method : 'get'
  } )
}

export const getTemplateListNoToken = ( query ) => {
  return request( {
    url : '/publicScanTemplate/listNoToken',
    headers : {
      isToken : false
    },
    method : 'get',
    params : query
  } )
}

export const getIndustryListNoToken = () => {
  return request( {
    url : '/system/dict/data/listNoToken?dictType=t_industry',
    method : 'get'
  } )
}

export const getSceneListNoToken = () => {
  return request( {
    url : '/system/dict/data/listNoToken?dictType=t_scene',
    method : 'get'
  } )
}

// 登录静态生码
export const getCodeStatus = ( data ) => {
  return request( {
    url : '/qr/codeStatus',
    method : 'POST',
    data : data
  } )
}

// 添加我的模板库
export const updateTempName = ( data ) => {
  return request( {
    url : '/productTemplate/updateName',
    method : 'POST',
    data : data
  } )
}
// 点击量
export const updateClick = ( data ) => {
  return request( {
    url : `/openApi/scanTemplate/click/${data}`,
    method : 'put',
    data : data
  } )
}
// 下载量
export const updateDown = ( data ) => {
  return request( {
    url : `/openApi/productTemplate/down/${data}`,
    method : 'put',
    data : data
  } )
}
// 下载量
// export const updateDown = (data) => {
//   return request({
//     url: `/openApi/scanTemplate/down/${data}`,
//     method: 'put',
//     data: data
//   })
// }

// 二维码
export const getQrStyleList = () => {
  return request( {
    url : '/system/dict/data/list?dictType=qrcode_style',
    method : 'get'
  } )
}
export const getQrBase64 = ( data ) => {
  return request( {
    url : '/qrcode/getQrBase64',
    method : 'POST',
    data : data
  } )
}

// 二维码样式模板
export const qrCssTempList = ( data ) => {
  return request( {
    url : '/qrcode/getCommonList',
    method : 'get',
    params : data
  } )
}
export const qrCssList = ( data ) => {
  return request( {
    url : '/qrcode/list',
    method : 'get',
    params : data
  } )
}
export const saveQrCss = ( data ) => {
  return request( {
    url : '/qrcode',
    method : 'POST',
    data : data
  } )
}
export const editQrCss = ( data ) => {
  return request( {
    url : '/qrcode',
    method : 'put',
    data : data
  } )
}
export const delQrCss = ( data ) => {
  return request( {
    url : `/qrcode/${data}`,
    method : 'delete'
  } )
}

export const operateQrTemp = ( data ) => {
  return request( {
    url : '/qrcode/operateToCommon',
    method : 'POST',
    data : data
  } )
}
export const saveToUser = ( data ) => {
  return request( {
    url : '/qr/codeStatus/batch',
    method : 'POST',
    data : data
  } )
}
export const uploadActionImg = ( data ) => {
  return request( {
    url : '/file/upload/image?pageFunc=门户图片静态码生码',
    method : 'POST',
    data : data
  } )
}
export const uploadActionFile = ( data ) => {
  return request( {
    url : '/file/upload/file?pageFunc=门户文件静态码生码',
    method : 'POST',
    data : data
  } )
}
export const uploadActionAudio = ( data ) => {
  return request( {
    url : '/file/upload/audio?pageFunc=门户音频静态码生码',
    method : 'POST',
    data : data
  } )
}
export const uploadActionVideo = ( data ) => {
  return request( {
    url : '/file/upload/video?pageFunc=门户视频静态码生码',
    method : 'POST',
    data : data
  } )
}
// 公共模板库
export function getTemplateData( data ) {
  return request( {
    url : '/form/template/public/listNoToken',
    method : 'get',
    params : data
  } )
}

// 二维码样式模板新
export const qrPubCssTempList = ( data ) => {
  return request( {
    url : '/qrcode/getCommonList',
    method : 'get',
    params : data
  } )
}
// export const getQrStyleList = () => {
//   return request( {
//     url : '/system/dict/data/list?dictType=qrcode_style',
//     method : 'get'
//   } )
// }
export const qrMyCssList = ( data ) => {
  return request( {
    url : '/qrcode/list',
    method : 'get',
    params : data
  } )
}
export const saveMyQrCss = ( data ) => {
  return request( {
    url : '/qrcode',
    method : 'POST',
    data : data
  } )
}
export const delMyQrCss = ( data ) => {
  return request( {
    url : `/qrcode/${data}`,
    method : 'delete'
  } )
}

export const getStaticCodeInfo = ( id ) => {
  return request( {
    url : `/qr/codeStatus/${id}`,
    method : 'get'
  } )
}
