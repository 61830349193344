import request from '@/utils/request'

/**
 * 新闻接口地址
 */
const newsapi = process.env.CMS_NEWSAPI_URL

/**
 * 请求新闻接口
 * @param url 请求地址(不含前面的http)
 */
async function getNewsApi( url ) {
  return request( newsapi + url )
}

/**
 * 新闻栏位对应的标签id
 */
const labelIds = {
  carousel : 42,
  singleImg : 43,
  hotNews : 44,
  msl : 45
}

// 查询列表
export async function listNewsLabel( ) {
  const list = await getNewsApi( '/client/article/label/view/0?siteId=1' )
  const resList = []
  for ( const item of list ) {
    resList.push( {
      dictCode : item.id,
      dictValue : item.id,
      dictLabel : item.title
    } )
  }
  return {
    rows : resList
  }
}

export async function listNewsChannel() {
  const list = await getNewsApi( '/client/channel/view/20?siteId=1' )
  const resList = []
  for ( const item of list ) {
    resList.push( {
      dictCode : item.id,
      dictValue : item.id,
      dictLabel : item.title
    } )
  }
  return {
    rows : resList
  }
}

// 查询信息列表
export async function listPolicyList( query ) {
  if ( query.pageNum ) {
    query.pageIndex = query.pageNum
  }
  if ( query.query ) {
    query.keyword = query.query
  }
  const ret = await getNewsApi( prepareQuery( '/client/article/view/news/list?clientId=1', query ) )
  return {
    code : ret.code,
    total : ret.count,
    rows : ret.data
  }
}
export async function listNewsList( query ) {
  const ret = await getNewsApi( '/client/article/view/channel/news/5?labelId=5&siteId=1' )
  return {
    ret
  }
}
function prepareQuery( url, query ) {
  let param = ''
  for ( const key of Object.keys( query ) ) {
    if ( query[key] ) {
      param += '&' + key + '=' + encodeURIComponent( query[key] )
    }
  }
  if ( param ) {
    if ( url.indexOf( '?' ) === -1 ) {
      url += '?' + param.substring( 1 )
    } else {
      url += param
    }
  }
  return url
}

// 查询信息列表
export async function getNewsPosByType( posType ) {
  const url = '/client/article/view/news/6?siteId=1&labelId=' + labelIds[posType]
  const ret = await getNewsApi( url )
  return {
    data : ret
  }
}

// 二级节点
export function getSecondNodes() {
  return request( {
    url : '/idis/secondNode/listPage?pageNum=1&pageSize=100',
    method : 'get'
  } )
}

// 友情链接
export async function getLinks( posType ) {
  const url = '/client/link/view/6?siteId=1'
  const ret = await getNewsApi( url )
  return {
    data : ret
  }
}
