import request from '@/utils/request'

// 查询产品idis
export function queryWarrantyStatus( idisCode ) {
  return request( {
    url : '/wx/queryWarrantyStatus?idisCode=' + idisCode,
    method : 'get'
  } )
}

// 查询百度Token
export function getBaiDuToken() {
  return request( {
    url : '/wx/getBaiDuToken',
    method : 'get'
  } )
}

// 查询所有防伪模板
export function getSecurityTemplateListAll( scenario ) {
  return request( {
    url : '/product/securityTemplate/listAll?scenario=' + scenario,
    method : 'get'
  } )
}

// 查询产品防伪模板详细
export function getSecurityTemplate( id, scenario ) {
  return request( {
    url : '/product/securityTemplate/listAll?id=' + id + '&scenario=' + scenario,
    method : 'get'
  } )
}

// 查询产品模板详细
export function getDetailById( id ) {
  return request( {
    url : '/productTemplate/' + id,
    method : 'get'
  } )
}
export function getDetailsByIdNoToken( id ) {
  return request( {
    url : '/publicScanTemplate/noToken/' + id,
    method : 'get'
  } )
}

export function getRelatedProduct( id ) {
  return request( {
    url : '/productTemplate/relatedProduct?templateId=' + id,
    method : 'get'
  } )
}
// 新增产品防伪模板
export function addAntiCounterfeitValidate( query ) {
  return request( {
    url : '/wx/anti-counterfeit-validate',
    method : 'post',
    params : query
  } )
}
// 查询码列表
export function listTemplate( query ) {
  return request( {
    url : '/productTemplate/listTemplate',
    method : 'get',
    params : query
  } )
}

// 新增产品模板
export function addProductTemplate( data ) {
  return request( {
    url : '/productTemplate/addTemplate',
    method : 'post',
    data : data
  } )
}

// 修改产品模板
export function editProductTemplate( data ) {
  return request( {
    url : '/productTemplate/editTemplate',
    method : 'post',
    data : data
  } )
}

// 模板新增 + 生码
export function addTemplateAndCode( data ) {
  return request( {
    url : '/userScanTemplate/addTemplateAndCode',
    method : 'post',
    data : data
  } )
}

// 模板编辑 + 生码
export function editTemplateAndCode( data ) {
  return request( {
    url : '/productTemplate/editTemplateAndCode',
    method : 'post',
    data : data
  } )
}

// 删除产品
export function delProduct( id ) {
  return request( {
    url : '/product/product/' + id,
    method : 'delete'
  } )
}
// 真删除产品
export function delProductTrue( id ) {
  return request( {
    url : '/product/product/del/' + id,
    method : 'delete'
  } )
}

// 导出产品
export function exportProduct( query ) {
  return request( {
    url : '/product/product/export',
    method : 'get',
    params : query
  } )
}

// 是否展示
export function templateShowed( query ) {
  return request( {
    url : '/productTemplate/showed',
    method : 'put',
    data : query
  } )
}

// 配置configTemplate
export function configTemplate( query ) {
  return request( {
    url : '/productTemplate/configTemplate',
    method : 'post',
    data : query
  } )
}

// 删除活码
export function delTemplate( id ) {
  return request( {
    url : '/productTemplate/' + id,
    method : 'delete'
  } )
}

// 模板分享
export function shareTemplate( data ) {
  return request( {
    url : '/productTemplate/shareTemplate',
    method : 'post',
    data : data
  } )
}
